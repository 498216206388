import React from 'react'
import Navigation from '../Navigation/Navigation'
import "./style.css"
import {Container, Col,Row} from "react-bootstrap"
//import Typical from 'react-typical'
import Typed from 'react-typed';
import PlayerHeader from './PlayerHeader/PlayerHeader'
import FilmBg from '../FilmBg/FilmBg';
import BgCarousel from '../BgCarousel/BgCarousel';
import Chart from '../Charts/Chart';
import Footer from '../Footer/Footer';

const BgHead = () => {
    return (
        <div id="wrapperbg">
        <div className="bghead">
            <Navigation/>

           <div className="mylayout">
               <Container fluid>
                <Row>
                    <Col md={6}>
        <div className="bgplayer">

            <PlayerHeader/>

        </div>


                    </Col>


                    <Col md={6}>
                       
                    <div id="tipical">
                        <h2>ایزی استاک</h2>

                        <Typed
                    strings={['لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه 2','لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. ']}
                    typeSpeed={40}
                    backSpeed={50}
                    loop
                    style={{direction:"rtl",float:"right",height:"60px",marginRight:"42px"}}
                />
<div style={{clear:"both"}}></div>

                        {/* <Typical
                            steps={['ابزاری برای رصد و ارزیابی سهم‌های بازار از نظر شاخص‌های بنیادی', 2000, 'با ثبت نام سریع وارد پنل مدیریتی خود شده و از امکانات متوع ایزی استاک استفاده نمایید', 2000]}
                            loop={Infinity}
                            wrapper="p"
                           
                        /> */}
                        </div>
                    <div id="register">
                        <ul>
                            <li><button id="first">ورود به حساب</button></li>
                            <li><button id="second">ثبت نام</button></li>
                           
                        </ul>

                    </div>
<div style={{clear:"both"}}></div>
                    <div id="callNumber">
                    <p>تلفن تماس: 77455579-021 
                    <br/> 
آدرس: شمال غربی میدان رسالت، پلاک ۸۶۵</p>

                    </div>
                  
                    </Col>
                </Row>

               </Container>

           </div>

        </div>

{/* second section */}

     <FilmBg/>

{/* third section */}

<BgCarousel/>

{/* chart view */}

<Chart/>

{/* Footer */}
<Footer/>

        </div>
    )
}

export default BgHead
