import React from 'react'
import "./BgCarousel.css"
import {Container,Row,Col} from "react-bootstrap"
import {Link} from "react-router-dom"
import pic from "../utils/chart.png"
import {Form} from "react-bootstrap"
const BgCarousel = () => {
    return (
        <div className="bgcarousel">



            <Container fluid style={{padding:"0"}}>
                <Row>
                    <Col>
                    <div className="table">

                        <h3>دسترسی به آمار شرکت ها</h3>
                        <p>تحلیل تکنیکال بازار برای یافتن زمان مناسب خرید و فروش</p>

                        
                       
                       <div className="tablewrapp">


                       <div className="select">
                            <label>برترین سهام ها از نظر:</label>
                         <select>
                             <option>ارزش سهام</option>
                             <option>سود سهام</option>
                             <option>آینده سهام</option>
                             <option>گزشته سهام</option>
                             <option>سلامت مالی</option>
                         </select>
                            
                        </div>




                       <table>
                <thead>
                    <tr>
                    <th>شرکت</th>
                    <th>نماد</th>
                    <th>امتیاز</th>
                    <th>بازدهی 7 روزه</th>
                    <th>بازدهی 30 روزه</th>
                    <th>تفاوت قیمت با ارزش ذاتی</th>
                    <th>نرخ Roe در آینده</th>
                    <th>بروزرسانی</th>
                    <th>اطلاعات تکمیلی</th>
                    </tr>
               
                </thead>
            <tbody>
                <tr>
                    <td>تولیدی چدن سازان</td>
                    <td>چدن</td>
                    <td>۱۰۰/۱۰۰</td>
                    <td>-۶%</td>
                    <td>-۱۲%</td>
                    <td>۴۴% پایین تر </td>
                    <td>۳۳%</td>
                    <td>۱۸ ساعت پیش</td>
                    <td><Link to="/notFound"><img src={pic} alt="chart"/></Link>‌</td>
                   
                </tr>
                <tr>
                <td>گروه صنعتی پاکشو</td>
                
                <td>پاکشو</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                   
                </tr>
                <tr>
                <td>شرکت س استان آذربایجان غربی</td>
                <td>وساغربی</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>بانک صادرات ایران</td>
                <td>وبصادر</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>ایرکا پارت صنعت</td>
                <td>خکار</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>تامین سرمایه نوین</td>
                <td>تنوین</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>شرکت س استان یزد</td>
                <td>وسیزد</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>شرکت س استان گلستان</td>
                <td>وسگلستا</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>لیزینگ‌صنعت‌ومعدن‌</td>
                <td>ولصنم</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
                <tr>
                <td>بانک تجارت</td>
                <td>وتجارت</td>
                <td>۱۰۰/۱۰۰	</td>
                <td>۱%	</td>
                <td>۵%	</td>
                <td>۴۳% پایین تر</td>
                <td>۵۷%	</td>
                <td>۱۸ ساعت پیش	</td>
                <td><Link to="/notFound"><img src={pic} alt="chart"/></Link></td>
                </tr>
            </tbody>
                    </table>
                       </div>
             




                    </div>
                    </Col>
                </Row>
            </Container>
           
        </div>
    )
}

export default BgCarousel
