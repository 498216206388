import React from 'react'
import "./Footer.css"
import pic from "../utils/logo.png"
import {Container,Row,Col} from "react-bootstrap"
import {Link} from "react-router-dom"
const Footer = () => {
    return (
        <div className="footer">
          
           <div id="innerTicket">
               
<Container>
    <Row>
        <Col md={4}>
        <img src={pic} alt="logo" id="logo"/>
        </Col>

        <Col md={4}>
        <div id="list">
        <ul>
          <li> <Link to="/notFound">سرویس ها</Link></li>
          <li> <Link to="/notFound">درباره ما</Link></li>
          <li> <Link to="/notFound">ثبت نام</Link></li>
          <li> <Link to="/notFound">ورود</Link></li>
      </ul>
        </div>
     

        </Col>

        <Col md={4}>
        <div id="address">
        <h6>تلفن تماس: ۷۷۴۵۵۵۷۹-۰۲۱</h6>
       <p>آدرس: شمال غربی میدان رسالت، پلاک ۸۶۵</p>
        </div>
       
        </Col>

       
    </Row>
</Container>
           </div>


        </div>
    )
}

export default Footer
