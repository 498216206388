
import './App.css';
import {BrowserRouter as Router , Route, Switch} from "react-router-dom"
import BgHead from './Components/BgHead/BgHead';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageNotFound from './Components/Page404/PageNotFound';

function App() {
  return (
    <div className="App">

<Router>
<Switch>
<Route exact path="/" component={BgHead}/>
<Route path="/notFound" component={PageNotFound}/>

</Switch>

</Router>
     
    </div>
  );
}

export default App;
