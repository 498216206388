import React from 'react'
import "./PageNotFound.css"
const PageNotFound = () => {
    return (
        <div className="notfound">
            
        </div>
    )
}

export default PageNotFound
