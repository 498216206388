import React from 'react'
import {Container,Row,Col} from "react-bootstrap"
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import HC_more from "highcharts/highcharts-more"; 
import mydata from "./data"
import "./Chart.css"
import pic from "../utils/question (1).png"
import pic2 from "../utils/question (2).png"
HC_more(Highcharts);



const Chart = () => {

    var seriesOptions = [],
    seriesCounter = 0,
    names = ['MSFT', 'AAPL', 'GOOG'];


    function success(data) {
        var name = this.url.match(/(msft|aapl|goog)/)[0].toUpperCase();
        var i = names.indexOf(name);
        seriesOptions[i] = {
          name: name,
          data: data
        };
      
        // As we're loading the data asynchronously, we don't know what order it
        // will arrive. So we keep a counter and create the chart when all the data is loaded.
        seriesCounter += 1;
      
       
      }


    const options = {
        credits: {
            enabled: false
         },
        navigator:{
            enabled: false
          },
  
      yAxis: {
        labels: {
          formatter: function () {
            return (this.value > 0 ? ' + ' : '') + this.value + '%';
          }
        },
        plotLines: [{
          value: 0,
          width: 2,
          color: 'silver'
        }]
      },
  
      plotOptions: {
         
        series: {
          compare: 'percent',
         
        }
      },
  
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
        valueDecimals: 2,
        split: true
      },
  
      series: [{
          data:mydata
      }]
      }


     const option2={
        chart: {
            type: 'area',
            zoomType:"x"
           
        },

        credits: {
            enabled: false
         },

        title: {
            text: ''
        },

        navigator:{
            enabled: false
          },
        yAxis: {
            reversed: true,
            showFirstLabel: false,
            showLastLabel: true
        },

        series: [{
            name: '',
            data: mydata,
            threshold: null,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                },
                stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                ]
            },
            tooltip: {
                valueDecimals: 2
            }
        }]
    
     }


    return (
        <div id="chart">
          <Container>
              <Row>
                  <Col md={6}>
                      <div style={{width:"100%"}} id="firstChart">
                          <h6>تغییرات وضعیت بازار</h6>
                      <HighchartsReact
    highcharts={Highcharts}
    options={options}
    constructorType={'stockChart'}
    containerProps={{ style: { height: "100%",width:"100%" } }}
  />
                      </div>

                 

                  </Col>





                  <Col md={6}>
                  <div id="secondChart">
                      <h6>تغییرات شاخص</h6>
                  <HighchartsReact
                 
                 highcharts={Highcharts}
                 options={option2}
                 constructorType={'stockChart'}
                 containerProps={{ style: { height: "100%",width:"100%" } }}
               />
                      </div>

                 


                  </Col>
              </Row>

<div id="between">
  <h4>حجم</h4>
</div>

          <Row>
           
              
     <Col sm={12} md={6} lg={6} xl={3}>

      <div className="boxTable">
        <div className="title">
        <h5>حجم‌هاي مشکوک</h5>
        <span><img src={pic} alt="icon"/></span>
        </div>

        <div className="tablelittle">
          <table>
            <thead>
              <tr>
              <th>شاخص حجم</th>
              <th>حجم امروز</th>
              <th>نماد</th>
              </tr>

            </thead>
      <tbody>
        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

      </tbody>



          </table>
          
        </div>
        
      </div>


</Col>



<Col sm={12} md={6} lg={6} xl={3}>
<div className="boxTable">
        <div className="title">
        <h5 style={{color:"#008000"}}>بيشترين حجم 10 روزه</h5>
        <span><img src={pic2} alt="icon"/></span>
        </div>

        <div className="tablelittle">
          <table>
            <thead>
              <tr>
              <th>شاخص حجم</th>
              <th>حجم امروز</th>
              <th>نماد</th>
              </tr>

            </thead>
      <tbody>
        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

      </tbody>



          </table>
          
        </div>
        
      </div>


</Col>




<Col sm={12} md={6} lg={6} xl={3}>

<div className="boxTable">
        <div className="title">
        <h5 style={{color:"#008000"}}>بيشترين حجم امروز به 30 روز</h5>
        <span><img src={pic2} alt="icon"/></span>
        </div>

        <div className="tablelittle">
          <table>
            <thead>
              <tr>
              <th>شاخص حجم</th>
              <th>حجم امروز</th>
              <th>نماد</th>
              </tr>

            </thead>
      <tbody>
        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

      </tbody>



          </table>
          
        </div>
        
      </div>
</Col>



<Col sm={12} md={6} lg={6} xl={3}>
<div className="boxTable">
        <div className="title">
        <h5>بيشترين حجم معاملات</h5>
        <span><img src={pic} alt="icon"/></span>
        </div>

        <div className="tablelittle">
          <table>
            <thead>
              <tr>
              <th>شاخص حجم</th>
              <th>حجم امروز</th>
              <th>نماد</th>
              </tr>

            </thead>
      <tbody>
        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

        <tr>
          <td>28.3</td>
          <td>1,058</td>
          <td>فسديد</td>
        </tr>

      </tbody>



          </table>
          
        </div>
        
      </div>
</Col>


          
       

          </Row>


          </Container>
        </div>
    )
}

export default Chart
