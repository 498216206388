import React from 'react'
import ModalVideo from 'react-modal-video'
import "react-modal-video/css/modal-video.min.css"
import video from "../../utils/demo.mp4"
import "./PlayerHeader.css"
import pic from "../../utils/demo.jpg"
import pic2 from "../../utils/play.png"
const PlayerHeader = () => {
    const [isOpen, setOpen] = React.useState(false)


    return (
        <div className="bgplayer">
            <img src={pic} id="picback"/>
            <ModalVideo allowFullScreen={true} channel='custom' url={video} autoplay isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)} />

<button className="pulsingButton" onClick={()=> setOpen(true)}><img src={pic2} alt="play"/></button>




        </div>
    )
}

export default PlayerHeader
