import React from 'react'
import "./FilmBg.css"
import {Container, Col,Row} from "react-bootstrap"
import videos from "../utils/Part1(Standard).m4v"
import videos2 from "../utils/Part2(standard).m4v"
import pic from "../utils/آنلاین.gif"
const FilmBg = () => {
    return (
        <div>
        <div className="filmbg">
            <Container >
                <Row>
                    <Col md={6}>
                    <div id="titr">

                        <h3 >با اطمینان سرمایه گذاری کنید <br/>
در کاریزما، حرفه ای ها سرمایه شما را مدیریت می کنند.</h3>

<p>نخستین شرکت مدیریت دارایی در ایران<br/>
دارای بیش از چهل هزار مشتری فعال<br/>
مدیر بزرگترین صندوق با درآمد ثابت در بورس<br/>
تحت نظارت سازمان بورس و اوراق بهادار</p>
<i style={{clear:"both"}}></i>

<button type="button">چرا کاریزما ؟</button>


                    </div>
                    </Col>


                    <Col md={6}>
                   <div className="effectpic">
                   
                       <video class="hideVideo" preload="none"  width="100%" height="100%" controls autoPlay muted loop style={{pointerEvents:"none",paddingTop:"80px"}}>
   <source src={videos} id="unique_video_id"/>
     
   </video>

   <div className="textOver">
       <div className="secondText">
           <h6 >شرکت شما چه خدماتی در زمینه سرمایه گذاری ارائه میدهد؟</h6>
           <div style={{clear:"both"}}></div>
           <p> <img src={pic} alt="logo"/>   </p>
           
       </div>
       
   </div>

                   </div>


                    </Col>

                </Row>

            </Container>
        </div>
        <div className="filmbg">
        <Container>
            <Row>
                


                <Col md={6}>
               <div className="effectpic">
               
                   <video class="hideVideo" preload="none"  width="100%" height="100%" controls autoPlay muted loop style={{pointerEvents:"none"}}>
<source src={videos2} id="unique_video_id"/>
 
</video>



               </div>


                </Col>

                <Col md={6}>
                <div id="titr">

                    <h3>مشاوره هوشمند کاریزما <br/>
                    راهنمای شما برای انتخاب بهترین سرمایه گذاری.</h3>

<p>افراد شرایط متفاوتی از نظر میزان ریسک پذیری و بازه زمانی مورد پذیرش برای سرمایه  گذاری دارند. مشاور هوشمند ما با بررسی شرایط و اهداف شما، بهترین توصیه را به شما خواهد کرد.</p>
<i style={{clear:"both"}}></i>

<button type="button">شروع مشاوره</button>


                </div>
                </Col>

            </Row>

        </Container>
    </div>
    </div>
    )
}

export default FilmBg
