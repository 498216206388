import React from 'react'
import "./style.css"
import {Link} from "react-router-dom"
import {Navbar,Nav,Container} from 'react-bootstrap'
import pic from "../utils/logo.png"
const Navigation = () => {
    return (
       
<div className="navigation">
        <Navbar  expand="lg">
  <Container fluid>
    
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Link to="/notFound">تماس با ما</Link>
        <Link to="/notFound">درباره ما</Link>
        <Link to="/notFound">سرویس ها</Link>
        <Link to="/notFound">ورود</Link>
        <Link to="/notFound">ثبت نام</Link>
        <Link to="/">خانه</Link>
       
      </Nav>
    </Navbar.Collapse>
    <Navbar.Brand><img src={pic} alt="logo" id="logo"/></Navbar.Brand>
  </Container>
</Navbar>
</div>
    )
}

export default Navigation
