
const mydata= [

    [1147651200000,23.15],
    [1147737600000,23.01],
    [1147824000000,22.73],
    [1147910400000,22.83],
    [1147996800000,22.56],
    [1148256000000,22.88],
    [1148342400000,22.79],
    [1148428800000,23.50],
    [1148515200000,23.74],
    [1148601600000,23.72],
    [1148947200000,23.15],
    [1149033600000,22.65],
    
    [1149120000000,22.82],
    [1149206400000,22.76],
    [1149465600000,22.50],
    [1149552000000,22.13],
    [1149638400000,22.04],
    [1149724800000,22.11],
    [1149811200000,21.92],
    [1150070400000,21.71],
    [1150156800000,21.51],
    [1150243200000,21.88],
    [1150329600000,22.07],
    [1150416000000,22.10],
    [1150675200000,22.55],
    [1150761600000,22.56],
    [1150848000000,23.08],
    [1150934400000,22.88],
    [1151020800000,22.50],
    [1151280000000,22.82],
    [1151366400000,22.86],
    [1151452800000,23.16],
    [1151539200000,23.47],
    [1151625600000,23.30],
    
    [1151884800000,23.70],
    [1152057600000,23.35],
    [1152144000000,23.48],
    [1152230400000,23.30],
    [1152489600000,23.50],
    [1152576000000,23.10],
    [1152662400000,22.64],
    [1152748800000,22.26],
    [1152835200000,22.29],
    [1153094400000,22.48],
    [1153180800000,22.74],
    [1153267200000,23.40],
    [1153353600000,22.85],
    [1153440000000,23.87],
    [1153699200000,24.00],
    [1153785600000,24.22],
    [1153872000000,24.37],
    [1153958400000,23.87],
    [1154044800000,24.25],
    [1154304000000,24.06],
    
    [1154390400000,23.99],
    [1154476800000,24.30],
    [1154563200000,24.21],
    [1154649600000,24.29],
    [1154908800000,24.22],
    [1154995200000,24.34],
    [1155081600000,24.44],
    [1155168000000,24.46],
    [1155254400000,24.43],
    [1155513600000,24.53],
    [1155600000000,24.62],
    [1155686400000,24.70],
    [1155772800000,24.70],
    [1155859200000,25.79],
    [1156118400000,26.12],
    [1156204800000,25.62],
    [1156291200000,25.67],
    [1156377600000,25.74],
    [1156464000000,25.85],
    [1156723200000,25.95],
    [1156809600000,25.84],
    [1156896000000,25.80],
    [1156982400000,25.70],
    
    [1157068800000,25.84],
    [1157414400000,25.61],
    [1157500800000,25.61],
    [1157587200000,25.43],
    [1157673600000,25.60],
    [1157932800000,25.91],
    [1158019200000,25.93],
    [1158105600000,25.98],
    [1158192000000,26.33],
    [1158278400000,26.85],
    [1158537600000,26.79],
    [1158624000000,26.86],
    [1158710400000,27.18],
    [1158796800000,26.90],
    [1158883200000,26.66],
    [1159142400000,26.95],
    [1159228800000,27.20],
    [1159315200000,27.44],
    [1159401600000,27.40],
    [1159488000000,27.35],
    
    [1159747200000,27.36],
    [1159833600000,27.37],
    [1159920000000,27.94],
    [1160006400000,27.92],
    [1160092800000,27.87],
    [1160352000000,27.72],
    [1160438400000,27.69],
    [1160524800000,27.54],
    [1160611200000,28.22],
    [1160697600000,28.37],
    [1160956800000,28.45],
    [1161043200000,28.44],
    [1161129600000,28.52],
    [1161216000000,28.29],
    [1161302400000,28.43],
    [1161561600000,28.45],
    [1161648000000,28.28],
    [1161734400000,28.31],
    [1161820800000,28.35],
    [1161907200000,28.34],
    [1162166400000,28.53],
    [1162252800000,28.71],
    
    [1162339200000,28.81],
    [1162425600000,28.77],
    [1162512000000,28.73],
    [1162771200000,28.84],
    [1162857600000,28.95],
    [1162944000000,28.98],
    [1163030400000,29.26],
    [1163116800000,29.24],
    [1163376000000,29.35],
    [1163462400000,29.23],
    [1163548800000,29.12],
    [1163635200000,29.47],
    [1163721600000,29.40],
    [1163980800000,29.89],
    [1164067200000,29.92],
    [1164153600000,29.92],
    [1164326400000,29.76],
    [1164585600000,29.48],
    [1164672000000,29.39],
    [1164758400000,29.57],
    [1164844800000,29.36],
    
    [1164931200000,29.12],
    [1165190400000,29.33],
    [1165276800000,29.13],
    [1165363200000,28.99],
    [1165449600000,28.85],
    [1165536000000,29.40],
    [1165795200000,29.54],
    [1165881600000,29.43],
    [1165968000000,29.55],
    [1166054400000,30.07],
    [1166140800000,30.19],
    [1166400000000,29.89],
    [1166486400000,29.99],
    [1166572800000,30.09],
    [1166659200000,29.98],
    [1166745600000,29.64],
    [1167091200000,29.99],
    [1167177600000,30.02],
    [1167264000000,29.98],
    [1167350400000,29.86],
    
    [1167782400000,29.86],
    [1167868800000,29.81],
    [1167955200000,29.64],
    [1168214400000,29.93],
    [1168300800000,29.96],
    [1168387200000,29.66],
    [1168473600000,30.70],
    [1168560000000,31.21],
    [1168905600000,31.16],
    [1168992000000,31.10],
    [1169078400000,31.00],
    [1169164800000,31.11],
    [1169424000000,30.72],
    [1169510400000,30.74],
    [1169596800000,31.09],
    [1169683200000,30.45],
    [1169769600000,30.60],
    [1170028800000,30.53],
    [1170115200000,30.48],
    [1170201600000,30.86],
    
    [1170288000000,30.56],
    [1170374400000,30.19],
    [1170633600000,29.61],
    [1170720000000,29.51],
    [1170806400000,29.37],
    [1170892800000,29.26],
    [1170979200000,28.98],
    [1171238400000,28.94],
    [1171324800000,29.03],
    [1171411200000,29.40],
    [1171497600000,29.46],
    [1171584000000,28.74],
    [1171929600000,28.83],
    [1172016000000,29.35],
    [1172102400000,29.39],
    [1172188800000,28.90],
    [1172448000000,29.07],
    [1172534400000,27.87],
    [1172620800000,28.17],
    
    [1172707200000,28.09],
    [1172793600000,27.76],
    [1173052800000,27.55],
    [1173139200000,27.83],
    [1173225600000,27.61],
    [1173312000000,27.32],
    [1173398400000,27.29],
    [1173657600000,27.44],
    [1173744000000,26.72],
    [1173830400000,27.40],
    [1173916800000,27.28],
    [1174003200000,27.33],
    [1174262400000,27.83],
    [1174348800000,27.84],
    [1174435200000,28.52],
    [1174521600000,28.27],
    [1174608000000,28.02],
    [1174867200000,28.22],
    [1174953600000,27.72],
    [1175040000000,27.64],
    [1175126400000,27.75],
    [1175212800000,27.87],
    
    [1175472000000,27.74],
    [1175558400000,27.87],
    [1175644800000,28.50],
    [1175731200000,28.55],
    [1176076800000,28.57],
    [1176163200000,28.40],
    [1176249600000,28.11],
    [1176336000000,28.54],
    [1176422400000,28.61],
    [1176681600000,28.73],
    [1176768000000,28.85],
    [1176854400000,28.60],
    [1176940800000,28.69],
    [1177027200000,29.02],
    [1177286400000,28.78],
    [1177372800000,28.79],
    [1177459200000,28.99],
    [1177545600000,29.10],
    [1177632000000,30.12],
    [1177891200000,29.94],
    
    [1177977600000,30.40],
    [1178064000000,30.61],
    [1178150400000,30.97],
    [1178236800000,30.56],
    [1178496000000,30.71],
    [1178582400000,30.75],
    [1178668800000,30.78],
    [1178755200000,30.58],
    [1178841600000,30.89],
    [1179100800000,30.97],
    [1179187200000,30.90],
    [1179273600000,31.07],
    [1179360000000,30.98],
    [1179446400000,30.83],
    [1179705600000,31.05],
    [1179792000000,30.69],
    [1179878400000,30.58],
    [1179964800000,30.17],
    [1180051200000,30.48],
    [1180396800000,30.79],
    [1180483200000,31.11],
    [1180569600000,30.69],
    
    [1180656000000,30.59],
    [1180915200000,30.72],
    [1181001600000,30.58],
    [1181088000000,30.29],
    [1181174400000,29.62],
    [1181260800000,30.05],
    [1181520000000,30.02],
    [1181606400000,29.85],
    [1181692800000,30.39],
    [1181779200000,30.52],
    [1181865600000,30.49],
    [1182124800000,30.51],
    [1182211200000,30.46],
    [1182297600000,30.01],
    [1182384000000,30.22],
    [1182470400000,29.49],
    [1182729600000,29.49],
    [1182816000000,29.52],
    [1182902400000,29.87],
    [1182988800000,29.83],
    [1183075200000,29.47],
    
    [1183334400000,29.74],
    [1183420800000,30.02],
    [1183593600000,29.99],
    [1183680000000,29.97],
    [1183939200000,29.87],
    [1184025600000,29.33],
    [1184112000000,29.49],
    [1184198400000,30.07],
    [1184284800000,29.82],
    [1184544000000,30.03],
    [1184630400000,30.78],
    [1184716800000,30.92],
    [1184803200000,31.51],
    [1184889600000,31.16],
    [1185148800000,31.19],
    [1185235200000,30.80],
    [1185321600000,30.71],
    [1185408000000,29.98],
    [1185494400000,29.39],
    [1185753600000,29.40],
    [1185840000000,28.99],
    
    [1185926400000,29.30],
    [1186012800000,29.52],
    [1186099200000,28.96],
    [1186358400000,29.54],
    [1186444800000,29.55],
    [1186531200000,30.00],
    [1186617600000,29.30],
    [1186704000000,28.71],
    [1186963200000,28.63],
    [1187049600000,28.27],
    [1187136000000,28.10],
    [1187222400000,27.81],
    [1187308800000,28.25],
    [1187568000000,28.26],
    [1187654400000,28.07],
    [1187740800000,28.22],
    [1187827200000,28.30],
    [1187913600000,28.81],
    [1188172800000,28.49],
    [1188259200000,27.93],
    [1188345600000,28.59],
    [1188432000000,28.45],
    [1188518400000,28.73],
    
    [1188864000000,28.81],
    [1188950400000,28.48],
    [1189036800000,28.91],
    [1189123200000,28.44],
    [1189382400000,28.48],
    [1189468800000,28.93],
    [1189555200000,28.93],
    [1189641600000,29.16],
    [1189728000000,29.04],
    [1189987200000,28.72],
    [1190073600000,28.93],
    [1190160000000,28.67],
    [1190246400000,28.42],
    [1190332800000,28.65],
    [1190592000000,29.08],
    [1190678400000,29.56],
    [1190764800000,29.50],
    [1190851200000,29.49],
    [1190937600000,29.46],
    
    [1191196800000,29.77],
    [1191283200000,29.70],
    [1191369600000,29.45],
    [1191456000000,29.71],
    [1191542400000,29.84],
    [1191801600000,29.84],
    [1191888000000,30.10],
    [1191974400000,30.23],
    [1192060800000,29.91],
    [1192147200000,30.17],
    [1192406400000,30.04],
    [1192492800000,30.32],
    [1192579200000,31.08],
    [1192665600000,31.16],
    [1192752000000,30.17],
    [1193011200000,30.51],
    [1193097600000,30.90],
    [1193184000000,31.25],
    [1193270400000,31.99],
    [1193356800000,35.03],
    [1193616000000,34.57],
    [1193702400000,35.57],
    [1193788800000,36.81],
    
    [1193875200000,37.06],
    [1193961600000,37.06],
    [1194220800000,36.73],
    [1194307200000,36.41],
    [1194393600000,35.52],
    [1194480000000,34.74],
    [1194566400000,33.73],
    [1194825600000,33.38],
    [1194912000000,34.46],
    [1194998400000,33.93],
    [1195084800000,33.76],
    [1195171200000,34.09],
    [1195430400000,33.96],
    [1195516800000,34.58],
    [1195603200000,34.23],
    [1195776000000,34.11],
    [1196035200000,32.97],
    [1196121600000,33.06],
    [1196208000000,33.70],
    [1196294400000,33.59],
    [1196380800000,33.60],
    
    [1196640000000,32.92],
    [1196726400000,32.77],
    [1196812800000,34.15],
    [1196899200000,34.55],
    [1196985600000,34.53],
    [1197244800000,34.76],
    [1197331200000,34.10],
    [1197417600000,34.47],
    [1197504000000,35.22],
    [1197590400000,35.31],
    [1197849600000,34.39],
    [1197936000000,34.74],
    [1198022400000,34.79],
    [1198108800000,35.52],
    [1198195200000,36.06],
    [1198454400000,36.58],
    [1198627200000,36.61],
    [1198713600000,35.97],
    [1198800000000,36.12],
    [1199059200000,35.60],
    
    [1199232000000,35.22],
    [1199318400000,35.37],
    [1199404800000,34.38],
    [1199664000000,34.61],
    [1199750400000,33.45],
    [1199836800000,34.44],
    [1199923200000,34.33],
    [1200009600000,33.91],
    [1200268800000,34.39],
    [1200355200000,34.00],
    [1200441600000,33.23],
    [1200528000000,33.11],
    [1200614400000,33.01],
    [1200960000000,31.99],
    [1201046400000,31.93],
    [1201132800000,33.25],
    [1201219200000,32.94],
    [1201478400000,32.72],
    [1201564800000,32.60],
    [1201651200000,32.20],
    [1201737600000,32.60],
    
    [1201824000000,30.45],
    [1202083200000,30.19],
    [1202169600000,29.07],
    [1202256000000,28.52],
    [1202342400000,28.12],
    [1202428800000,28.56],
    [1202688000000,28.21],
    [1202774400000,28.34],
    [1202860800000,28.96],
    [1202947200000,28.50],
    [1203033600000,28.42],
    [1203379200000,28.17],
    [1203465600000,28.22],
    [1203552000000,28.10],
    [1203638400000,27.68],
    [1203897600000,27.84],
    [1203984000000,28.38],
    [1204070400000,28.26],
    [1204156800000,27.93],
    [1204243200000,27.20],
    
    [1204502400000,26.99],
    [1204588800000,27.59],
    [1204675200000,28.12],
    [1204761600000,27.57],
    [1204848000000,27.87],
    [1205107200000,28.05],
    [1205193600000,29.28],
    [1205280000000,28.63],
    [1205366400000,28.62],
    [1205452800000,27.96],
    [1205712000000,28.30],
    [1205798400000,29.42],
    [1205884800000,28.62],
    [1205971200000,29.18],
    [1206316800000,29.17],
    [1206403200000,29.14],
    [1206489600000,28.56],
    [1206576000000,28.05],
    [1206662400000,27.91],
    [1206921600000,28.38],
    
    [1207008000000,29.50],
    [1207094400000,29.16],
    [1207180800000,29.00],
    [1207267200000,29.16],
    [1207526400000,29.16],
    [1207612800000,28.75],
    [1207699200000,28.89],
    [1207785600000,29.11],
    [1207872000000,28.28],
    [1208131200000,28.06],
    [1208217600000,28.25],
    [1208304000000,28.95],
    [1208390400000,29.22],
    [1208476800000,30.00],
    [1208736000000,30.42],
    [1208822400000,30.25],
    [1208908800000,31.45],
    [1208995200000,31.80],
    [1209081600000,29.83],
    [1209340800000,28.99],
    [1209427200000,28.64],
    [1209513600000,28.52],
    
    [1209600000000,29.40],
    [1209686400000,29.24],
    [1209945600000,29.08],
    [1210032000000,29.70],
    [1210118400000,29.21],
    [1210204800000,29.27],
    [1210291200000,29.39],
    [1210550400000,29.99],
    [1210636800000,29.78],
    [1210723200000,29.93],
    [1210809600000,30.45],
    [1210896000000,29.99],
    [1211155200000,29.46],
    [1211241600000,28.76],
    [1211328000000,28.25],
    [1211414400000,28.47],
    [1211500800000,28.05],
    [1211846400000,28.44],
    [1211932800000,28.18],
    [1212019200000,28.31],
    [1212105600000,28.32],
    
    [1212364800000,27.80],
    [1212451200000,27.31],
    [1212537600000,27.54],
    [1212624000000,28.30],
    [1212710400000,27.49],
    [1212969600000,27.71],
    [1213056000000,27.89],
    [1213142400000,27.12],
    [1213228800000,28.24],
    [1213315200000,29.07],
    [1213574400000,28.93],
    [1213660800000,28.80],
    [1213747200000,28.46],
    [1213833600000,28.93],
    [1213920000000,28.23],
    [1214179200000,27.97],
    [1214265600000,27.73],
    [1214352000000,28.35],
    [1214438400000,27.75],
    [1214524800000,27.63],
    [1214784000000,27.51],
    
    [1214870400000,26.87],
    [1214956800000,25.88],
    [1215043200000,25.98],
    [1215388800000,26.03],
    [1215475200000,25.85],
    [1215561600000,25.23],
    [1215648000000,25.45],
    [1215734400000,25.25],
    [1215993600000,25.15],
    [1216080000000,26.15],
    [1216166400000,27.26],
    [1216252800000,27.52],
    [1216339200000,25.86],
    [1216598400000,25.64],
    [1216684800000,25.80],
    [1216771200000,26.43],
    [1216857600000,25.44],
    [1216944000000,26.16],
    [1217203200000,25.50],
    [1217289600000,26.11],
    [1217376000000,26.23],
    [1217462400000,25.72],
    
    [1217548800000,25.44],
    [1217808000000,25.28],
    [1217894400000,26.21],
    [1217980800000,27.02],
    [1218067200000,27.39],
    [1218153600000,28.13],
    [1218412800000,27.90],
    [1218499200000,28.12],
    [1218585600000,27.91],
    [1218672000000,27.91],
    [1218758400000,27.81],
    [1219017600000,27.69],
    [1219104000000,27.32],
    [1219190400000,27.29],
    [1219276800000,27.18],
    [1219363200000,27.84],
    [1219622400000,27.66],
    [1219708800000,27.27],
    [1219795200000,27.56],
    [1219881600000,27.94],
    [1219968000000,27.29],
    
    [1220313600000,27.10],
    [1220400000000,26.90],
    [1220486400000,26.35],
    [1220572800000,25.65],
    [1220832000000,26.12],
    [1220918400000,26.10],
    [1221004800000,26.44],
    [1221091200000,27.34],
    [1221177600000,27.62],
    [1221436800000,26.82],
    [1221523200000,25.99],
    [1221609600000,24.57],
    [1221696000000,25.26],
    [1221782400000,25.16],
    [1222041600000,25.40],
    [1222128000000,25.44],
    [1222214400000,25.72],
    [1222300800000,26.61],
    [1222387200000,27.40],
    [1222646400000,25.01],
    [1222732800000,26.69],
    
    [1222819200000,26.48],
    [1222905600000,26.25],
    [1222992000000,26.32],
    [1223251200000,24.91],
    [1223337600000,23.23],
    [1223424000000,23.01],
    [1223510400000,22.30],
    [1223596800000,21.50],
    [1223856000000,25.50],
    [1223942400000,24.10],
    [1224028800000,22.66],
    [1224115200000,24.19],
    [1224201600000,23.93],
    [1224460800000,24.72],
    [1224547200000,23.36],
    [1224633600000,21.53],
    [1224720000000,22.32],
    [1224806400000,21.96],
    [1225065600000,21.18],
    [1225152000000,23.10],
    [1225238400000,23.00],
    [1225324800000,22.63],
    [1225411200000,22.33],
    
    [1225670400000,22.62],
    [1225756800000,23.53],
    [1225843200000,22.08],
    [1225929600000,20.88],
    [1226016000000,21.50],
    [1226275200000,21.30],
    [1226361600000,21.20],
    [1226448000000,20.30],
    [1226534400000,21.25],
    [1226620800000,20.06],
    [1226880000000,19.32],
    [1226966400000,19.62],
    [1227052800000,18.29],
    [1227139200000,17.53],
    [1227225600000,19.68],
    [1227484800000,20.69],
    [1227571200000,19.99],
    [1227657600000,20.49],
    [1227744000000,20.49],
    [1227830400000,20.22],
    
    [1228089600000,18.61],
    [1228176000000,19.15],
    [1228262400000,19.87],
    [1228348800000,19.11],
    [1228435200000,19.87],
    [1228694400000,21.01],
    [1228780800000,20.60],
    [1228867200000,20.61],
    [1228953600000,19.45],
    [1229040000000,19.36],
    [1229299200000,19.04],
    [1229385600000,20.11],
    [1229472000000,19.66],
    [1229558400000,19.30],
    [1229644800000,19.12],
    [1229904000000,19.18],
    [1229990400000,19.28],
    [1230076800000,19.17],
    [1230163200000,19.17],
    [1230249600000,19.13],
    [1230508800000,18.96],
    [1230595200000,19.34],
    [1230681600000,19.44],
    
    [1230768000000,19.44],
    [1230854400000,20.33],
    [1231113600000,20.52],
    [1231200000000,20.76],
    [1231286400000,19.51],
    [1231372800000,20.12],
    [1231459200000,19.52],
    [1231718400000,19.47],
    [1231804800000,19.82],
    [1231891200000,19.09],
    [1231977600000,19.24],
    [1232064000000,19.71],
    [1232409600000,18.48],
    [1232496000000,19.38],
    [1232582400000,17.11],
    [1232668800000,17.20],
    [1232928000000,17.63],
    [1233014400000,17.66],
    [1233100800000,18.04],
    [1233187200000,17.59],
    [1233273600000,17.10],
    
    [1233532800000,17.83],
    [1233619200000,18.50],
    [1233705600000,18.63],
    [1233792000000,19.04],
    [1233878400000,19.66],
    [1234137600000,19.44],
    [1234224000000,18.80],
    [1234310400000,19.21],
    [1234396800000,19.26],
    [1234483200000,19.09],
    [1234828800000,18.09],
    [1234915200000,18.12],
    [1235001600000,17.91],
    [1235088000000,18.00],
    [1235347200000,17.21],
    [1235433600000,17.17],
    [1235520000000,16.96],
    [1235606400000,16.42],
    [1235692800000,16.15],
    
    [1235952000000,15.79],
    [1236038400000,15.88],
    [1236124800000,16.12],
    [1236211200000,15.27],
    [1236297600000,15.28],
    [1236556800000,15.15],
    [1236643200000,16.48],
    [1236729600000,17.11],
    [1236816000000,17.01],
    [1236902400000,16.65],
    [1237161600000,16.25],
    [1237248000000,16.90],
    [1237334400000,16.96],
    [1237420800000,17.14],
    [1237507200000,17.06],
    [1237766400000,18.33],
    [1237852800000,17.93],
    [1237939200000,17.88],
    [1238025600000,18.83],
    [1238112000000,18.13],
    [1238371200000,17.48],
    [1238457600000,18.37],
    
    [1238544000000,19.31],
    [1238630400000,19.29],
    [1238716800000,18.75],
    [1238976000000,18.76],
    [1239062400000,18.76],
    [1239148800000,19.19],
    [1239235200000,19.67],
    [1239321600000,19.67],
    [1239580800000,19.59],
    [1239667200000,19.35],
    [1239753600000,18.83],
    [1239840000000,19.76],
    [1239926400000,19.20],
    [1240185600000,18.61],
    [1240272000000,18.97],
    [1240358400000,18.78],
    [1240444800000,18.92],
    [1240531200000,20.91],
    [1240790400000,20.40],
    [1240876800000,19.93],
    [1240963200000,20.25],
    [1241049600000,20.26],
    
    [1241136000000,20.24],
    [1241395200000,20.19],
    [1241481600000,19.79],
    [1241568000000,19.79],
    [1241654400000,19.32],
    [1241740800000,19.42],
    [1242000000000,19.32],
    [1242086400000,19.89],
    [1242172800000,19.75],
    [1242259200000,20.06],
    [1242345600000,20.22],
    [1242604800000,20.60],
    [1242691200000,20.31],
    [1242777600000,20.38],
    [1242864000000,19.82],
    [1242950400000,19.75],
    [1243296000000,20.34],
    [1243382400000,20.13],
    [1243468800000,20.45],
    [1243555200000,20.89],
    
    [1243814400000,21.40],
    [1243900800000,21.40],
    [1243987200000,21.73],
    [1244073600000,21.83],
    [1244160000000,22.14],
    [1244419200000,22.05],
    [1244505600000,22.08],
    [1244592000000,22.55],
    [1244678400000,22.83],
    [1244764800000,23.33],
    [1245024000000,23.42],
    [1245110400000,23.45],
    [1245196800000,23.68],
    [1245283200000,23.50],
    [1245369600000,24.07],
    [1245628800000,23.28],
    [1245715200000,23.34],
    [1245801600000,23.47],
    [1245888000000,23.79],
    [1245974400000,23.35],
    [1246233600000,23.86],
    [1246320000000,23.77],
    
    [1246406400000,24.04],
    [1246492800000,23.37],
    [1246579200000,23.37],
    [1246838400000,23.20],
    [1246924800000,22.53],
    [1247011200000,22.56],
    [1247097600000,22.44],
    [1247184000000,22.39],
    [1247443200000,23.23],
    [1247529600000,23.11],
    [1247616000000,24.12],
    [1247702400000,24.44],
    [1247788800000,24.29],
    [1248048000000,24.53],
    [1248134400000,24.83],
    [1248220800000,24.80],
    [1248307200000,25.56],
    [1248393600000,23.45],
    [1248652800000,23.11],
    [1248739200000,23.47],
    [1248825600000,23.80],
    [1248912000000,23.81],
    [1248998400000,23.52],
    
    [1249257600000,23.83],
    [1249344000000,23.77],
    [1249430400000,23.81],
    [1249516800000,23.46],
    [1249603200000,23.56],
    [1249862400000,23.42],
    [1249948800000,23.13],
    [1250035200000,23.53],
    [1250121600000,23.62],
    [1250208000000,23.69],
    [1250467200000,23.25],
    [1250553600000,23.58],
    [1250640000000,23.65],
    [1250726400000,23.67],
    [1250812800000,24.41],
    [1251072000000,24.64],
    [1251158400000,24.64],
    [1251244800000,24.55],
    [1251331200000,24.69],
    [1251417600000,24.68],
    [1251676800000,24.65],
    
    [1251763200000,24.00],
    [1251849600000,23.86],
    [1251936000000,24.11],
    [1252022400000,24.62],
    [1252368000000,24.82],
    [1252454400000,24.78],
    [1252540800000,25.00],
    [1252627200000,24.86],
    [1252886400000,25.00],
    [1252972800000,25.20],
    [1253059200000,25.20],
    [1253145600000,25.30],
    [1253232000000,25.26],
    [1253491200000,25.30],
    [1253577600000,25.77],
    [1253664000000,25.71],
    [1253750400000,25.94],
    [1253836800000,25.55],
    [1254096000000,25.83],
    [1254182400000,25.75],
    [1254268800000,25.72],
    
    [1254355200000,24.88],
    [1254441600000,24.96],
    [1254700800000,24.64],
    [1254787200000,25.11],
    [1254873600000,25.10],
    [1254960000000,25.67],
    [1255046400000,25.55],
    [1255305600000,25.72],
    [1255392000000,25.81],
    [1255478400000,25.96],
    [1255564800000,26.71],
    [1255651200000,26.50],
    [1255910400000,26.36],
    [1255996800000,26.37],
    [1256083200000,26.58],
    [1256169600000,26.59],
    [1256256000000,28.02],
    [1256515200000,28.68],
    [1256601600000,28.59],
    [1256688000000,28.02],
    [1256774400000,28.22],
    [1256860800000,27.73],
    
    [1257120000000,27.88],
    [1257206400000,27.53],
    [1257292800000,28.06],
    [1257379200000,28.47],
    [1257465600000,28.52],
    [1257724800000,28.99],
    [1257811200000,29.01],
    [1257897600000,29.12],
    [1257984000000,29.36],
    [1258070400000,29.63],
    [1258329600000,29.54],
    [1258416000000,30.00],
    [1258502400000,30.11],
    [1258588800000,29.78],
    [1258675200000,29.62],
    [1258934400000,29.94],
    [1259020800000,29.91],
    [1259107200000,29.79],
    [1259193600000,29.79],
    [1259280000000,29.22],
    [1259539200000,29.41],
    
    [1259625600000,30.01],
    [1259712000000,29.78],
    [1259798400000,29.83],
    [1259884800000,29.98],
    [1260144000000,29.79],
    [1260230400000,29.57],
    [1260316800000,29.71],
    [1260403200000,29.87],
    [1260489600000,29.85],
    [1260748800000,30.11],
    [1260835200000,30.02],
    [1260921600000,30.10],
    [1261008000000,29.60],
    [1261094400000,30.36],
    [1261353600000,30.52],
    [1261440000000,30.82],
    [1261526400000,30.92],
    [1261612800000,31.00],
    [1261699200000,31.00],
    [1261958400000,31.17],
    [1262044800000,31.39],
    [1262131200000,30.96],
    [1262217600000,30.48],
    
    [1262304000000,30.48],
    [1262563200000,30.95],
    [1262649600000,30.96],
    [1262736000000,30.77],
    [1262822400000,30.45],
    [1262908800000,30.66],
    [1263168000000,30.27],
    [1263254400000,30.07],
    [1263340800000,30.35],
    [1263427200000,30.96],
    [1263513600000,30.86],
    [1263772800000,30.86],
    [1263859200000,31.10],
    [1263945600000,30.58],
    [1264032000000,30.01],
    [1264118400000,28.96],
    [1264377600000,29.32],
    [1264464000000,29.50],
    [1264550400000,29.67],
    [1264636800000,29.16],
    [1264723200000,28.18],
    
    [1264982400000,28.41],
    [1265068800000,28.46],
    [1265155200000,28.63],
    [1265241600000,27.84],
    [1265328000000,28.02],
    [1265587200000,27.72],
    [1265673600000,28.01],
    [1265760000000,27.99],
    [1265846400000,28.12],
    [1265932800000,27.93],
    [1266192000000,27.93],
    [1266278400000,28.35],
    [1266364800000,28.59],
    [1266451200000,28.97],
    [1266537600000,28.77],
    [1266796800000,28.73],
    [1266883200000,28.33],
    [1266969600000,28.63],
    [1267056000000,28.60],
    [1267142400000,28.67],
    
    [1267401600000,29.02],
    [1267488000000,28.46],
    [1267574400000,28.46],
    [1267660800000,28.63],
    [1267747200000,28.59],
    [1268006400000,28.63],
    [1268092800000,28.80],
    [1268179200000,28.97],
    [1268265600000,29.18],
    [1268352000000,29.27],
    [1268611200000,29.29],
    [1268697600000,29.37],
    [1268784000000,29.63],
    [1268870400000,29.61],
    [1268956800000,29.59],
    [1269216000000,29.60],
    [1269302400000,29.88],
    [1269388800000,29.65],
    [1269475200000,30.01],
    [1269561600000,29.66],
    [1269820800000,29.59],
    [1269907200000,29.77],
    [1269993600000,29.29],
    
    [1270080000000,29.16],
    [1270166400000,29.16],
    [1270425600000,29.27],
    [1270512000000,29.32],
    [1270598400000,29.35],
    [1270684800000,29.92],
    [1270771200000,30.34],
    [1271030400000,30.32],
    [1271116800000,30.45],
    [1271203200000,30.82],
    [1271289600000,30.87],
    [1271376000000,30.67],
    [1271635200000,31.04],
    [1271721600000,31.36],
    [1271808000000,31.33],
    [1271894400000,31.39],
    [1271980800000,30.96],
    [1272240000000,31.11],
    [1272326400000,30.84],
    [1272412800000,30.91],
    [1272499200000,31.00],
    [1272585600000,30.54],
    
    [1272844800000,30.86],
    [1272931200000,30.13],
    [1273017600000,29.85],
    [1273104000000,28.98],
    [1273190400000,28.21],
    [1273449600000,28.94],
    [1273536000000,28.88],
    [1273622400000,29.44],
    [1273708800000,29.24],
    [1273795200000,28.93],
    [1274054400000,28.94],
    [1274140800000,28.60],
    [1274227200000,28.24],
    [1274313600000,27.11],
    [1274400000000,26.84],
    [1274659200000,26.27],
    [1274745600000,26.07],
    [1274832000000,25.01],
    [1274918400000,26.00],
    [1275004800000,25.80],
    [1275264000000,25.80],
    
    [1275350400000,25.89],
    [1275436800000,26.46],
    [1275523200000,26.86],
    [1275609600000,25.79],
    [1275868800000,25.29],
    [1275955200000,25.11],
    [1276041600000,24.79],
    [1276128000000,25.00],
    [1276214400000,25.66],
    [1276473600000,25.50],
    [1276560000000,26.58],
    [1276646400000,26.32],
    [1276732800000,26.37],
    [1276819200000,26.44],
    [1277078400000,25.95],
    [1277164800000,25.77],
    [1277251200000,25.31],
    [1277337600000,25.00],
    [1277424000000,24.53],
    [1277683200000,24.31],
    [1277769600000,23.31],
    [1277856000000,23.01],
    
    [1277942400000,23.16],
    [1278028800000,23.27],
    [1278288000000,23.27],
    [1278374400000,23.82],
    [1278460800000,24.30],
    [1278547200000,24.41],
    [1278633600000,24.27],
    [1278892800000,24.83],
    [1278979200000,25.13],
    [1279065600000,25.44],
    [1279152000000,25.51],
    [1279238400000,24.89],
    [1279497600000,25.23],
    [1279584000000,25.48],
    [1279670400000,25.12],
    [1279756800000,25.84],
    [1279843200000,25.81],
    [1280102400000,26.10],
    [1280188800000,26.16],
    [1280275200000,25.95],
    [1280361600000,26.03],
    [1280448000000,25.81],
    
    [1280707200000,26.33],
    [1280793600000,26.16],
    [1280880000000,25.73],
    [1280966400000,25.37],
    [1281052800000,25.55],
    [1281312000000,25.61],
    [1281398400000,25.07],
    [1281484800000,24.86],
    [1281571200000,24.49],
    [1281657600000,24.40],
    [1281916800000,24.50],
    [1282003200000,24.71],
    [1282089600000,24.82],
    [1282176000000,24.44],
    [1282262400000,24.23],
    [1282521600000,24.28],
    [1282608000000,24.04],
    [1282694400000,24.10],
    [1282780800000,23.82],
    [1282867200000,23.93],
    [1283126400000,23.64],
    [1283212800000,23.46],
    
    [1283299200000,23.90],
    [1283385600000,23.94],
    [1283472000000,24.29],
    [1283731200000,24.29],
    [1283817600000,23.96],
    [1283904000000,23.93],
    [1283990400000,24.01],
    [1284076800000,23.85],
    [1284336000000,25.11],
    [1284422400000,25.03],
    [1284508800000,25.12],
    [1284595200000,25.33],
    [1284681600000,25.22],
    [1284940800000,25.43],
    [1285027200000,25.15],
    [1285113600000,24.61],
    [1285200000000,24.43],
    [1285286400000,24.78],
    [1285545600000,24.73],
    [1285632000000,24.68],
    [1285718400000,24.50],
    [1285804800000,24.49],
    
    [1285891200000,24.38],
    [1286150400000,23.91],
    [1286236800000,24.35],
    [1286323200000,24.43],
    [1286409600000,24.53],
    [1286496000000,24.57],
    [1286755200000,24.59],
    [1286841600000,24.83],
    [1286928000000,25.34],
    [1287014400000,25.23],
    [1287100800000,25.54],
    [1287360000000,25.82],
    [1287446400000,25.10],
    [1287532800000,25.31],
    [1287619200000,25.42],
    [1287705600000,25.38],
    [1287964800000,25.19],
    [1288051200000,25.90],
    [1288137600000,26.05],
    [1288224000000,26.28],
    [1288310400000,26.66],
    
    [1288569600000,26.95],
    [1288656000000,27.39],
    [1288742400000,27.03],
    [1288828800000,27.14],
    [1288915200000,26.85],
    [1289174400000,26.81],
    [1289260800000,26.95],
    [1289347200000,26.94],
    [1289433600000,26.68],
    [1289520000000,26.27],
    [1289779200000,26.20],
    [1289865600000,25.81],
    [1289952000000,25.57],
    [1290038400000,25.84],
    [1290124800000,25.69],
    [1290384000000,25.73],
    [1290470400000,25.12],
    [1290556800000,25.37],
    [1290729600000,25.25],
    [1290988800000,25.31],
    [1291075200000,25.26],
    
    [1291161600000,26.04],
    [1291248000000,26.89],
    [1291334400000,27.02],
    [1291593600000,26.84],
    [1291680000000,26.87],
    [1291766400000,27.23],
    [1291852800000,27.08],
    [1291939200000,27.34],
    [1292198400000,27.24],
    [1292284800000,27.62],
    [1292371200000,27.85],
    [1292457600000,27.99],
    [1292544000000,27.90],
    [1292803200000,27.81],
    [1292889600000,28.07],
    [1292976000000,28.19],
    [1293062400000,28.30],
    [1293408000000,28.07],
    [1293494400000,28.01],
    [1293580800000,27.97],
    [1293667200000,27.85],
    [1293753600000,27.91],
    
    [1294012800000,27.98],
    [1294099200000,28.09],
    [1294185600000,28.00],
    [1294272000000,28.82],
    [1294358400000,28.60],
    [1294617600000,28.22],
    [1294704000000,28.11],
    [1294790400000,28.55],
    [1294876800000,28.19],
    [1294963200000,28.30],
    [1295308800000,28.66],
    [1295395200000,28.47],
    [1295481600000,28.35],
    [1295568000000,28.02],
    [1295827200000,28.38],
    [1295913600000,28.45],
    [1296000000000,28.78],
    [1296086400000,28.87],
    [1296172800000,27.75],
    [1296432000000,27.72],
    
    [1296518400000,27.99],
    [1296604800000,27.94],
    [1296691200000,27.65],
    [1296777600000,27.77],
    [1297036800000,28.20],
    [1297123200000,28.28],
    [1297209600000,27.97],
    [1297296000000,27.50],
    [1297382400000,27.25],
    [1297641600000,27.23],
    [1297728000000,26.96],
    [1297814400000,27.02],
    [1297900800000,27.21],
    [1297987200000,27.06],
    [1298332800000,26.59],
    [1298419200000,26.59],
    [1298505600000,26.77],
    [1298592000000,26.55],
    [1298851200000,26.58],
    
    [1298937600000,26.16],
    [1299024000000,26.08],
    [1299110400000,26.20],
    [1299196800000,25.95],
    [1299456000000,25.72],
    [1299542400000,25.91],
    [1299628800000,25.89],
    [1299715200000,25.41],
    [1299801600000,25.68],
    [1300060800000,25.69],
    [1300147200000,25.39],
    [1300233600000,24.79],
    [1300320000000,24.78],
    [1300406400000,24.80],
    [1300665600000,25.33],
    [1300752000000,25.30],
    [1300838400000,25.54],
    [1300924800000,25.81],
    [1301011200000,25.62],
    [1301270400000,25.41],
    [1301356800000,25.49],
    [1301443200000,25.61],
    [1301529600000,25.39],
    
    [1301616000000,25.48],
    [1301875200000,25.55],
    [1301961600000,25.78],
    [1302048000000,26.15],
    [1302134400000,26.20],
    [1302220800000,26.07],
    [1302480000000,25.98],
    [1302566400000,25.64],
    [1302652800000,25.63],
    [1302739200000,25.42],
    [1302825600000,25.37],
    [1303084800000,25.08],
    [1303171200000,25.15],
    [1303257600000,25.76],
    [1303344000000,25.52],
    [1303689600000,25.61],
    [1303776000000,26.19],
    [1303862400000,26.38],
    [1303948800000,26.71],
    [1304035200000,25.92],
    
    [1304294400000,25.66],
    [1304380800000,25.81],
    [1304467200000,26.06],
    [1304553600000,25.79],
    [1304640000000,25.87],
    [1304899200000,25.83],
    [1304985600000,25.67],
    [1305072000000,25.36],
    [1305158400000,25.32],
    [1305244800000,25.03],
    [1305504000000,24.57],
    [1305590400000,24.52],
    [1305676800000,24.69],
    [1305763200000,24.72],
    [1305849600000,24.49],
    [1306108800000,24.17],
    [1306195200000,24.15],
    [1306281600000,24.19],
    [1306368000000,24.67],
    [1306454400000,24.76],
    [1306800000000,25.01],
    
    [1306886400000,24.43],
    [1306972800000,24.22],
    [1307059200000,23.90],
    [1307318400000,24.01],
    [1307404800000,24.06],
    [1307491200000,23.94],
    [1307577600000,23.96],
    [1307664000000,23.70],
    [1307923200000,24.04],
    [1308009600000,24.22],
    [1308096000000,23.74],
    [1308182400000,24.00],
    [1308268800000,24.26],
    [1308528000000,24.47],
    [1308614400000,24.76],
    [1308700800000,24.65],
    [1308787200000,24.63],
    [1308873600000,24.30],
    [1309132800000,25.20],
    [1309219200000,25.80],
    [1309305600000,25.62],
    [1309392000000,26.00],
    
    [1309478400000,26.02],
    [1309824000000,26.03],
    [1309910400000,26.33],
    [1309996800000,26.77],
    [1310083200000,26.92],
    [1310342400000,26.63],
    [1310428800000,26.54],
    [1310515200000,26.63],
    [1310601600000,26.47],
    [1310688000000,26.78],
    [1310947200000,26.59],
    [1311033600000,27.54],
    [1311120000000,27.06],
    [1311206400000,27.10],
    [1311292800000,27.53],
    [1311552000000,27.91],
    [1311638400000,28.08],
    [1311724800000,27.33],
    [1311811200000,27.72],
    [1311897600000,27.40],
    
    [1312156800000,27.27],
    [1312243200000,26.64],
    [1312329600000,26.92],
    [1312416000000,25.94],
    [1312502400000,25.68],
    [1312761600000,24.48],
    [1312848000000,25.58],
    [1312934400000,24.20],
    [1313020800000,25.19],
    [1313107200000,25.10],
    [1313366400000,25.51],
    [1313452800000,25.35],
    [1313539200000,25.24],
    [1313625600000,24.67],
    [1313712000000,24.05],
    [1313971200000,23.98],
    [1314057600000,24.72],
    [1314144000000,24.90],
    [1314230400000,24.57],
    [1314316800000,25.25],
    [1314576000000,25.84],
    [1314662400000,26.23],
    [1314748800000,26.60],
    
    [1314835200000,26.21],
    [1314921600000,25.80],
    [1315267200000,25.51],
    [1315353600000,26.00],
    [1315440000000,26.22],
    [1315526400000,25.74],
    [1315785600000,25.89],
    [1315872000000,26.04],
    [1315958400000,26.50],
    [1316044800000,26.99],
    [1316131200000,27.12],
    [1316390400000,27.21],
    [1316476800000,26.98],
    [1316563200000,25.99],
    [1316649600000,25.06],
    [1316736000000,25.06],
    [1316995200000,25.44],
    [1317081600000,25.67],
    [1317168000000,25.58],
    [1317254400000,25.45],
    [1317340800000,24.89],
    
    [1317600000000,24.53],
    [1317686400000,25.34],
    [1317772800000,25.89],
    [1317859200000,26.34],
    [1317945600000,26.25],
    [1318204800000,26.94],
    [1318291200000,27.00],
    [1318377600000,26.96],
    [1318464000000,27.18],
    [1318550400000,27.27],
    [1318809600000,26.98],
    [1318896000000,27.31],
    [1318982400000,27.13],
    [1319068800000,27.04],
    [1319155200000,27.16],
    [1319414400000,27.19],
    [1319500800000,26.81],
    [1319587200000,26.59],
    [1319673600000,27.25],
    [1319760000000,26.98],
    [1320019200000,26.63],
    
    [1320105600000,25.99],
    [1320192000000,26.01],
    [1320278400000,26.53],
    [1320364800000,26.25],
    [1320624000000,26.80],
    [1320710400000,27.16],
    [1320796800000,26.20],
    [1320883200000,26.28],
    [1320969600000,26.91],
    [1321228800000,26.76],
    [1321315200000,26.74],
    [1321401600000,26.07],
    [1321488000000,25.54],
    [1321574400000,25.30],
    [1321833600000,25.00],
    [1321920000000,24.79],
    [1322006400000,24.47],
    [1322179200000,24.30],
    [1322438400000,24.87],
    [1322524800000,24.84],
    [1322611200000,25.58],
    
    [1322697600000,25.28],
    [1322784000000,25.22],
    [1323043200000,25.70],
    [1323129600000,25.66],
    [1323216000000,25.60],
    [1323302400000,25.40],
    [1323388800000,25.70],
    [1323648000000,25.51],
    [1323734400000,25.76],
    [1323820800000,25.59],
    [1323907200000,25.56],
    [1323993600000,26.00],
    [1324252800000,25.53],
    [1324339200000,26.02],
    [1324425600000,25.76],
    [1324512000000,25.81],
    [1324598400000,26.03],
    [1324944000000,26.04],
    [1325030400000,25.82],
    [1325116800000,26.02],
    [1325203200000,25.96],
    
    [1325548800000,26.76],
    [1325635200000,27.40],
    [1325721600000,27.68],
    [1325808000000,28.10],
    [1326067200000,27.74],
    [1326153600000,27.84],
    [1326240000000,27.72],
    [1326326400000,28.00],
    [1326412800000,28.25],
    [1326758400000,28.26],
    [1326844800000,28.23],
    [1326931200000,28.12],
    [1327017600000,29.71],
    [1327276800000,29.73],
    [1327363200000,29.34],
    [1327449600000,29.56],
    [1327536000000,29.50],
    [1327622400000,29.23],
    [1327881600000,29.61],
    [1327968000000,29.53],
    
    [1328054400000,29.89],
    [1328140800000,29.95],
    [1328227200000,30.24],
    [1328486400000,30.20],
    [1328572800000,30.35],
    [1328659200000,30.66],
    [1328745600000,30.77],
    [1328832000000,30.50],
    [1329091200000,30.58],
    [1329177600000,30.25],
    [1329264000000,30.05],
    [1329350400000,31.28],
    [1329436800000,31.25],
    [1329782400000,31.44],
    [1329868800000,31.27],
    [1329955200000,31.37],
    [1330041600000,31.48],
    [1330300800000,31.35],
    [1330387200000,31.87],
    [1330473600000,31.74],
    
    [1330560000000,32.29],
    [1330646400000,32.08],
    [1330905600000,31.80],
    [1330992000000,31.56],
    [1331078400000,31.84],
    [1331164800000,32.01],
    [1331251200000,31.99],
    [1331510400000,32.04],
    [1331596800000,32.67],
    [1331683200000,32.77],
    [1331769600000,32.85],
    [1331856000000,32.60],
    [1332115200000,32.20],
    [1332201600000,31.99],
    [1332288000000,31.91],
    [1332374400000,32.00],
    [1332460800000,32.01],
    [1332720000000,32.59],
    [1332806400000,32.52],
    [1332892800000,32.19],
    [1332979200000,32.12],
    [1333065600000,32.26],
    
    [1333324800000,32.29],
    [1333411200000,31.94],
    [1333497600000,31.21],
    [1333584000000,31.52],
    [1333929600000,31.10],
    [1334016000000,30.47],
    [1334102400000,30.34],
    [1334188800000,30.98],
    [1334275200000,30.81],
    [1334534400000,31.08],
    [1334620800000,31.44],
    [1334707200000,31.14],
    [1334793600000,31.01],
    [1334880000000,32.42],
    [1335139200000,32.12],
    [1335225600000,31.92],
    [1335312000000,32.20],
    [1335398400000,32.11],
    [1335484800000,31.98],
    [1335744000000,32.02],
    
    [1335830400000,32.01],
    [1335916800000,31.80],
    [1336003200000,31.76],
    [1336089600000,30.98],
    [1336348800000,30.65],
    [1336435200000,30.50],
    [1336521600000,30.76],
    [1336608000000,30.74],
    [1336694400000,31.16],
    [1336953600000,30.68],
    [1337040000000,30.21],
    [1337126400000,29.90],
    [1337212800000,29.72],
    [1337299200000,29.27],
    [1337558400000,29.75],
    [1337644800000,29.76],
    [1337731200000,29.11],
    [1337817600000,29.07],
    [1337904000000,29.06],
    [1338249600000,29.56],
    [1338336000000,29.34],
    [1338422400000,29.19],
    
    [1338508800000,28.45],
    [1338768000000,28.55],
    [1338854400000,28.50],
    [1338940800000,29.35],
    [1339027200000,29.23],
    [1339113600000,29.65],
    [1339372800000,28.90],
    [1339459200000,29.29],
    [1339545600000,29.13],
    [1339632000000,29.34],
    [1339718400000,30.02],
    [1339977600000,29.84],
    [1340064000000,30.70],
    [1340150400000,30.93],
    [1340236800000,30.14],
    [1340323200000,30.70],
    [1340582400000,29.86],
    [1340668800000,30.02],
    [1340755200000,30.17],
    [1340841600000,29.91],
    [1340928000000,30.59],
    
    [1341187200000,30.56],
    [1341273600000,30.76],
    [1341446400000,30.70],
    [1341532800000,30.18],
    [1341792000000,30.00],
    [1341878400000,29.74],
    [1341964800000,29.30],
    [1342051200000,28.63],
    [1342137600000,29.39],
    [1342396800000,29.44],
    [1342483200000,29.66],
    [1342569600000,30.45],
    [1342656000000,30.66],
    [1342742400000,30.12],
    [1343001600000,29.28],
    [1343088000000,29.15],
    [1343174400000,28.83],
    [1343260800000,29.16],
    [1343347200000,29.76],
    [1343606400000,29.64],
    [1343692800000,29.47],
    
    [1343779200000,29.41],
    [1343865600000,29.19],
    [1343952000000,29.75],
    [1344211200000,29.95],
    [1344297600000,30.26],
    [1344384000000,30.33],
    [1344470400000,30.50],
    [1344556800000,30.42],
    [1344816000000,30.39],
    [1344902400000,30.13],
    [1344988800000,30.20],
    [1345075200000,30.78],
    [1345161600000,30.90],
    [1345420800000,30.74],
    [1345507200000,30.80],
    [1345593600000,30.54],
    [1345680000000,30.26],
    [1345766400000,30.56],
    [1346025600000,30.69],
    [1346112000000,30.63],
    [1346198400000,30.65],
    [1346284800000,30.32],
    [1346371200000,30.82],
    
    [1346716800000,30.38],
    [1346803200000,30.39],
    [1346889600000,31.34],
    [1346976000000,30.95],
    [1347235200000,30.72],
    [1347321600000,30.79],
    [1347408000000,30.78],
    [1347494400000,30.94],
    [1347580800000,31.21],
    [1347840000000,31.21],
    [1347926400000,31.18],
    [1348012800000,31.05],
    [1348099200000,31.45],
    [1348185600000,31.19],
    [1348444800000,30.78],
    [1348531200000,30.39],
    [1348617600000,30.16],
    [1348704000000,30.16],
    [1348790400000,29.76],
    
    [1349049600000,29.49],
    [1349136000000,29.66],
    [1349222400000,29.86],
    [1349308800000,30.03],
    [1349395200000,29.85],
    [1349654400000,29.78],
    [1349740800000,29.28],
    [1349827200000,28.98],
    [1349913600000,28.95],
    [1350000000000,29.20],
    [1350259200000,29.51],
    [1350345600000,29.49],
    [1350432000000,29.59],
    [1350518400000,29.50],
    [1350604800000,28.64],
    [1350864000000,28.00],
    [1350950400000,28.05],
    [1351036800000,27.90],
    [1351123200000,27.88],
    [1351209600000,28.21],
    [1351641600000,28.54],
    
    [1351728000000,29.52],
    [1351814400000,29.50],
    [1352073600000,29.63],
    [1352160000000,29.86],
    [1352246400000,29.08],
    [1352332800000,28.81],
    [1352419200000,28.83],
    [1352678400000,28.22],
    [1352764800000,27.09],
    [1352851200000,26.84],
    [1352937600000,26.66],
    [1353024000000,26.52],
    [1353283200000,26.73],
    [1353369600000,26.71],
    [1353456000000,26.95],
    [1353628800000,27.70],
    [1353888000000,27.38],
    [1353974400000,27.08],
    [1354060800000,27.36],
    [1354147200000,26.95],
    [1354233600000,26.62],
    
    [1354492800000,26.43],
    [1354579200000,26.37],
    [1354665600000,26.67],
    [1354752000000,26.72],
    [1354838400000,26.46],
    [1355097600000,26.94],
    [1355184000000,27.32],
    [1355270400000,27.24],
    [1355356800000,27.11],
    [1355443200000,26.81],
    [1355702400000,27.10],
    [1355788800000,27.56],
    [1355875200000,27.31],
    [1355961600000,27.68],
    [1356048000000,27.45],
    [1356307200000,27.06],
    [1356480000000,26.86],
    [1356566400000,26.96],
    [1356652800000,26.55],
    [1356912000000,26.71],
    
    [1357084800000,27.62],
    [1357171200000,27.25],
    [1357257600000,26.74],
    [1357516800000,26.69],
    [1357603200000,26.55],
    [1357689600000,26.70],
    [1357776000000,26.46],
    [1357862400000,26.83],
    [1358121600000,26.89],
    [1358208000000,27.21],
    [1358294400000,27.04],
    [1358380800000,27.25],
    [1358467200000,27.25],
    [1358812800000,27.15],
    [1358899200000,27.61],
    [1358985600000,27.63],
    [1359072000000,27.88],
    [1359331200000,27.91],
    [1359417600000,28.01],
    [1359504000000,27.85],
    [1359590400000,27.45],
    
    [1359676800000,27.93],
    [1359936000000,27.44],
    [1360022400000,27.50],
    [1360108800000,27.34],
    [1360195200000,27.28],
    [1360281600000,27.55],
    [1360540800000,27.86],
    [1360627200000,27.88],
    [1360713600000,28.03],
    [1360800000000,28.04],
    [1360886400000,28.01],
    [1361232000000,28.04],
    [1361318400000,27.87],
    [1361404800000,27.49],
    [1361491200000,27.76],
    [1361750400000,27.37],
    [1361836800000,27.37],
    [1361923200000,27.81],
    [1362009600000,27.80],
    
    [1362096000000,27.95],
    [1362355200000,28.15],
    [1362441600000,28.35],
    [1362528000000,28.09],
    [1362614400000,28.14],
    [1362700800000,28.00],
    [1362960000000,27.87],
    [1363046400000,27.91],
    [1363132800000,27.92],
    [1363219200000,28.14],
    [1363305600000,28.04],
    [1363564800000,28.10],
    [1363651200000,28.18],
    [1363737600000,28.32],
    [1363824000000,28.11],
    [1363910400000,28.25],
    [1364169600000,28.16],
    [1364256000000,28.16],
    [1364342400000,28.37],
    [1364428800000,28.60],
    
    [1364774400000,28.61],
    [1364860800000,28.80],
    [1364947200000,28.56],
    [1365033600000,28.60],
    [1365120000000,28.70],
    [1365379200000,28.59],
    [1365465600000,29.61],
    [1365552000000,30.28],
    [1365638400000,28.94],
    [1365724800000,28.79],
    [1365984000000,28.69],
    [1366070400000,28.97],
    [1366156800000,28.82],
    [1366243200000,28.79],
    [1366329600000,29.76],
    [1366588800000,30.83],
    [1366675200000,30.60],
    [1366761600000,31.76],
    [1366848000000,31.94],
    [1366934400000,31.79],
    [1367193600000,32.61],
    [1367280000000,33.10],
    
    [1367366400000,32.72],
    [1367452800000,33.16],
    [1367539200000,33.49],
    [1367798400000,33.75],
    [1367884800000,33.31],
    [1367971200000,32.99],
    [1368057600000,32.66],
    [1368144000000,32.69]
    ]
    export default  mydata